<template>
  <v-container fluid class='align-self-sm-stretch justify-space-between'>
    <v-col class='pa-0 py-3'>
      <v-tooltip bottom>
        <template #activator='{ on }'>
          <v-icon v-on='on' @click='$emit("close")'>
            mdi-arrow-left-circle
          </v-icon>
        </template>
        {{ $t('BACK') }}
      </v-tooltip>
    </v-col>
    <v-col>
      <v-row>
        <span class='subtitle-2'>{{ $t('OPERATOR') }}</span>
      </v-row>
      <v-row>
        <v-autocomplete
          ref='autocomplete'
          v-model='selectedOperators'
          :items='operators'
          item-text='name'
          return-object
          :readonly='lockAutocomplete'
          :search-input='autocompleteSearchInput'
          multiple
          chips
          color='primary'
          deletable-chips
          clearable
          @change='setOperator'
        >
          <template #selection='data'>
            <v-chip
              :key='data.item.account_id'
              :input-value='data.selected'
              v-bind='data.attrs'
              close
              outlined
              color='primary'
              @click:close='removeOperator(data.item)'
            >
              {{ data.item.name }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-row>
    </v-col>
    <v-col class='card-footer'>
      <v-btn
        color='secondary'
        dark
        @click='applyFilter'
      >
        {{ $t('SAVE') }}
      </v-btn>
    </v-col>
  </v-container>
</template>
<script>
  import axios from 'axios';

  export default {
    data () {
      return {
        showFilters: false,
        endDate: null,
        operators: [],
        autocompleteData: null,
        lockAutocomplete: false,
        autocompleteSearchInput: '',
        selectedOperators: [],
      };
    },
    mounted: function () {
      this.fetchData();
    },
    methods: {
      async fetchData () {
        const { data } = await axios({
          url: '/account-get-all',
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'GET',
          responseType: 'json',
          withCredentials: true,
        });
        this.operators = data.list;
        this.operators.unshift({ name: 'Todos', account_id: -1 });
      },
      applyFilter () {
        this.$emit('applyFilters');
      },
      setOperator () {
        const index = this.selectedOperators.findIndex((o) => o.account_id === -1 && o.name === 'Todos');
        if (index >= 0) {
          const aux = this.selectedOperators[index];
          this.selectedOperators = [aux];
          this.lockAutocomplete = true;
          this.$refs.autocomplete.isMenuActive = false;
        }
        this.$emit('onFilter', { selectedOperators: this.selectedOperators });
      },
      removeOperator (operator) {
        const index = this.selectedOperators.findIndex((o) => o.account_id === operator.account_id);
        if (this.selectedOperators[index].name === 'Todos' && this.selectedOperators[index].account_id === -1) {
          this.lockAutocomplete = false;
        }
        this.selectedOperators.splice(index, 1);
        this.$emit('onFilter', { selectedOperators: this.selectedOperators });
      },
    },
  };

</script>
<style>
 .selected-chip {
    border-color: var(--v-secondary-base) !important;
    color: #FFFFFF !important;
    background-color: var(--v-accent-base) !important;
  }
  .selected-chip span {
    color: var(--v-secondary-base) !important;
  }
  .card-footer {
    text-align: end;
  }
</style>
